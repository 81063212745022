var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","lg10":""}},[_c('v-layout',{staticClass:"mb-4",attrs:{"column":"","justify-center":""}},[_c('h3',{staticClass:"logo"},[_vm._v("id")]),_c('span',{staticClass:"project"},[_vm._v("personal")])]),_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.checkEmailValidation($event)}}},[_c('validation-observer',{ref:"form"},[_c('validation-provider',{attrs:{"name":_vm.$t('auth.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"new-password","disabled":_vm.loading,"label":_vm.$t('auth.email'),"color":"tertiary","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1),_c('v-btn',{staticClass:"mt-5",attrs:{"block":"","large":"","depressed":"","color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.checkEmailValidation($event)}},nativeOn:{"click":function($event){_vm.loader = 'loading'}}},[_vm._v(" "+_vm._s(_vm.$t("auth.reset_password"))+" ")])],1)],1),_c('v-window-item',{attrs:{"value":2}},[_c('validation-observer',{ref:"token_form"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.changePassword($event)}}},[_c('validation-provider',{attrs:{"name":_vm.$t('auth.reset_password_code'),"rules":"required|min:4|max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('auth.reset_password_code'),"color":"tertiary","error-messages":errors},model:{value:(_vm.passwordResetToken),callback:function ($$v) {_vm.passwordResetToken=$$v},expression:"passwordResetToken"}})]}}])}),_c('validation-provider',{attrs:{"vid":"confirm","name":_vm.$t('auth.new_password'),"rules":"required|min:6|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"new-password","type":"password","color":"tertiary","label":_vm.$t('auth.new_password'),"error-messages":errors},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('auth.re_new_password'),"rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"new-password","color":"tertiary","type":"password","label":_vm.$t('auth.re_new_password'),"error-messages":errors},model:{value:(_vm.newPasswordConfirmation),callback:function ($$v) {_vm.newPasswordConfirmation=$$v},expression:"newPasswordConfirmation"}})]}}])}),_c('v-btn',{staticClass:"mt-5",attrs:{"loading":_vm.loading,"block":"","large":"","depressed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.changePassword($event)}}},[_vm._v(" "+_vm._s(_vm.$t("auth.change_password"))+" ")])],1)],1)],1)],1)],1),_c('v-flex',{staticClass:"mt-3",attrs:{"xs12":""}},[_c('v-btn',{attrs:{"block":"","text":"","disabled":_vm.loading,"depressed":"","to":{ name: 'login' }}},[_vm._v(" "+_vm._s(_vm.$t("auth.back_to_sign_in"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }