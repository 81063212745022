<template>
  <v-layout align-center justify-center>
    <v-flex xs12 lg10>
      <v-layout column justify-center class="mb-4">
        <h3 class="logo">id</h3>
        <span class="project">personal</span>
      </v-layout>
      <v-layout column>
        <v-flex xs12>
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-form @submit.prevent="checkEmailValidation">
                <validation-observer ref="form">
                  <validation-provider :name="$t('auth.email')" rules="required|email" v-slot="{ errors }">
                    <v-text-field
                      v-model="email"
                      autocomplete="new-password"
                      :disabled="loading"
                      :label="$t('auth.email')"
                      color="tertiary"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </validation-observer>
                <v-btn
                  block
                  large
                  depressed
                  color="primary"
                  class="mt-5"
                  :loading="loading"
                  :disabled="loading"
                  @click.native="loader = 'loading'"
                  @click.stop="checkEmailValidation"
                >
                  {{ $t("auth.reset_password") }}
                </v-btn>
              </v-form>
            </v-window-item>
            <v-window-item :value="2">
              <validation-observer ref="token_form">
                <v-form @submit.prevent="changePassword">
                  <validation-provider
                    :name="$t('auth.reset_password_code')"
                    rules="required|min:4|max:4"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="passwordResetToken"
                      :label="$t('auth.reset_password_code')"
                      color="tertiary"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    vid="confirm"
                    :name="$t('auth.new_password')"
                    rules="required|min:6|max:16"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="newPassword"
                      autocomplete="new-password"
                      type="password"
                      color="tertiary"
                      :label="$t('auth.new_password')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    :name="$t('auth.re_new_password')"
                    rules="required|password:@confirm"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="newPasswordConfirmation"
                      autocomplete="new-password"
                      color="tertiary"
                      type="password"
                      :label="$t('auth.re_new_password')"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <v-btn
                    :loading="loading"
                    block
                    large
                    depressed
                    color="primary"
                    class="mt-5"
                    @click.stop="changePassword"
                  >
                    {{ $t("auth.change_password") }}
                  </v-btn>
                </v-form>
              </validation-observer>
            </v-window-item>
          </v-window>
        </v-flex>
        <v-flex xs12 class="mt-3">
          <v-btn block text :disabled="loading" depressed :to="{ name: 'login' }">
            {{ $t("auth.back_to_sign_in") }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
  const ENTER_WORK_EMAIL_STEP = 1;
  const ENTER_PASSWORD_RESET_TOKEN_STEP = 2;

  export default {
    name: "forgot-password",
    data() {
      return {
        loading: false,
        email: null,
        step: 1,
        passwordResetToken: null,
        newPassword: null,
        newPasswordConfirmation: null
      };
    },
    methods: {
      focusPassword() {
        this.$nextTick(() => this.$refs.password.focus());
      },
      changePassword() {
        this.$refs.token_form.validate().then(valid => {
          if (valid) {
            const payload = {
              workEmail: this.email,
              passwordResetToken: this.passwordResetToken,
              newPassword: this.newPasswordConfirmation
            };
            this.loading = true;
            this.$api.userService
              .newPassword(payload)
              .then(({ data, status }) => {
                if (status === 201) {
                  this.$helpers.showNotification(this.$t("auth.password_change_successfully"), "success", true);
                  this.$router.replace({ name: "login" });
                } else if (status === 200 && data.error) {
                  if (data.error.code === 711) {
                    this.step = ENTER_PASSWORD_RESET_TOKEN_STEP;
                    this.$helpers.showNotification(this.$t("auth.invalid_reset_token"), "error", true);
                  } else if (data.error.code === 719) {
                    this.step = ENTER_WORK_EMAIL_STEP;
                    this.passwordResetToken = null;
                    this.$helpers.showNotification(this.$t("auth.reset_code_expired"), "error", true);
                  }
                }
              })
              .catch(console.error)
              .finally(() => (this.loading = false));
          }
        });
      },
      checkEmailValidation() {
        this.$refs.form.validate().then(valid => {
          if (valid) {
            this.loading = true;
            this.$api.userService
              .sendPasswordResetToken({ workEmail: this.email })
              .then(({ data, status }) => {
                if (status === 201) {
                  this.step = ENTER_PASSWORD_RESET_TOKEN_STEP;
                  this.$helpers.showNotification(this.$t("auth.send_sms_password_code"), "success", true);
                } else if (data.error) {
                  if (data.error.code === 711) {
                    this.$helpers.showNotification(this.$t("auth.email_not_found"), "error", true);
                  } else if (data.error.code === 718) {
                    this.$helpers.showNotification(this.$t("auth.reset_password_expired"), "error", true);
                  }
                }
              })
              .catch(console.error)
              .finally(() => (this.loading = false));
          }
        });
      }
    }
  };
</script>

<style scoped>
  .logo {
    font-family: "Paytone One", sans-serif;
    font-size: 100px;
    text-align: center;
    display: block;
    color: var(--v-primary-base);
  }

  .project {
    font-family: "Quicksand", sans-serif;
    font-size: 26px;
    color: var(--v-primary-base);
    text-align: center;
    margin-top: -30px;
  }
</style>
